<h1 class="mat-display">
    <mat-icon class="vertical-align-middle size-45">explore</mat-icon> CVE Info (Online DB)
</h1>
<mat-dialog-content>

    <mat-form-field class="example-full-width" color="accent" appearance="outline">
        <mat-label>CVE</mat-label>
        <input matInput placeholder="e.g.: CVE-2017-0144" [formControl]="mycve">
    </mat-form-field>

    <div *ngIf="results">

        <div style="width: 100%;" fxLayout.xs="column" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
            <div fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" *ngIf="results?.Published" fxFlex><b>Published:</b><br>{{ results?.Published }}</div>
            <div fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" *ngIf="results?.Modified" fxFlex><b>Modified:</b><br>{{ results?.Modified }}</div>
            <div fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" *ngIf="results?.assigner" fxFlex><b>Assigner:</b><br>{{ results?.assigner }}</div>
        </div>
        
        <p *ngIf="results?.summary"><b>Summary:</b> {{ results?.summary }}</p>
        <p>
            <mat-chip-list>
                <mat-chip *ngIf="results?.cvss">CVSS: {{ results?.cvss }}</mat-chip>
                <a *ngIf="results?.cwe" target="_blank" href="https://cwe.mitre.org/cgi-bin/jumpmenu.cgi?id={{ results?.cwe }}"><mat-chip>{{ results?.cwe }}</mat-chip></a>
            </mat-chip-list>
        </p>

        <h2 *ngIf="dataSource.data.length != 0">Github POCs</h2>

        <div *ngIf="dataSource.data.length != 0">
            <table mat-table [dataSource]="dataSource">
          
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> <b>Name</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
          
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> <b>Description</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
              </ng-container>
          
              <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> <b>Source</b> </th>
                <td mat-cell *matCellDef="let element"> {{element.html_url}} </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]"
                           showFirstLastButtons 
                           aria-label="Select page of periodic elements">
            </mat-paginator>
          </div>
          
          <h2 *ngIf="results?.references.length != 0">References</h2>

          <div *ngIf="results?.references">
              <table mat-table [dataSource]="dataSource2">
            
                <ng-container matColumnDef="url">
                  <th mat-header-cell *matHeaderCellDef> <b>Source</b> </th>
                  <td mat-cell *matCellDef="let element"> {{element}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
              </table>
            
              <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 20]"
                             showFirstLastButtons 
                             aria-label="Select page of periodic elements">
              </mat-paginator>
            </div>

    </div>

    <div *ngIf="show">
        Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    {{err_msg}}
</mat-dialog-content>
<mat-dialog-actions>

    <div>
      <button mat-raised-button color="accent" (click)="searchCVE()">
        <mat-icon matSuffix>search</mat-icon> Search CVE 
    </button>
  </div>

    <div style="margin-left: auto; margin-right: 0;" *ngIf="results">
        <button mat-raised-button color="accent" (click)="saveCVE()">
            <mat-icon matSuffix>save</mat-icon> Save & close
        </button>&nbsp;
        <button mat-flat-button color="primary" mat-dialog-close>Cancel</button>
    </div>

    
</mat-dialog-actions>